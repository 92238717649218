export const constants = {
  EMAIL: "asthgik.yv@gmail.com",
  LINKEDIN_URL: "https://www.linkedin.com/in/astghik-yeghiazaryan-732ba919b/",
  RESUME_URL:
    "https://drive.google.com/file/d/1Mv_EjkRVAuuAkY9fVMc_z06YpE3ze-R-/view?usp=drive_link",
};

export const rightSectionPoints = [
  "Identifying design challenges and offering creative solutions aligned with user and business objectives.",
  "Articulating the design decisions, rationales, and recommendations to stakeholders and partners. Working within Agile or Scrum frameworks to collaborate effectively with cross-functional teams such as designers, developers, product managers, and other team members throughout the design process.",
  "Oversee design testing to ensure that digital products meet user needs and expectations (including usability testing, A/B testing, and prototype testing to gather feedback and uncover challenges). Conduct usability testing sessions with real users to identify usability issues, analyze feedback, and make necessary iterations to improve the design.",
  "Understanding and empathizing with users to create designs that address their needs and pain points.",
];

export const contentMaxWidth = { maxWidth: 960 };
export const leftSectionPoints = [
  "Proficient in design tools like Figma, Adobe CS (XD, Illustrator, Photoshop)",
  "Organizing and structuring information to establish intuitive navigation and user flows. Developing low-fidelity wireframes and interactive prototypes for iterative design refinement. Applying design principles such as typography, color theory, and layout for visually appealing interfaces.",
  "Designing interactive elements to enhance user engagement and usability. Crafting responsive interfaces for consistent performance across various devices and screen sizes.",
  "Ensuring that designs are accessible to users with disabilities by following accessibility guidelines and best practices.",
  "Establishing design systems from scratch and integrating them with existing frameworks like Material-UI and Ant Design.",
];

export const octaveTags = [
  "Live project",
  "Product Design",
  "UX Design",
  "2024-25",
];
export const crioTags = ["Ongoing", "UI/UX Design", "2023-24"];
export const peerTreeTags = [
  "Ongoing",
  "Product Design",
  "UI/UX Design",
  "2024-25",
];

export const octaveProjectInfo = {
  title: "Octave",
  image: "OctaveCover_bkgqcb",
  tags: octaveTags,
  linkTo: "/Octave",
  description:
    "AI-powered platform that helps GTM teams develop messaging, generate content, and engage prospects- all from one collaborative workspace. Octave reimagines how companies define and drive their GTM strategy.",
  links: [
    { title: "Case study", linkTo: "/Octave", newTab: false },
    { title: "Website", linkTo: "https://www.octavehq.com/", newTab: true },
  ],
};

export const topSkillsTags = [
  "User Experience Design",
  "User-centered Design",
  "Visual Design",
  "Product Design",
  "Cross-team & cross- cultural collaboration",
];

export const augustProjectInfo = {
  title: "August",
  image: "AugustCover_jta5ic",
  tags: crioTags,
  linkTo: "/August",
  description:
    "AI-powered revolutionary app that serves as a personalized AI physician in the prevention of heart attacks and strokes for everyone. Patients benefit from a unique approach that fosters collaboration and support, ensuring a holistic journey towards heart health.",
  links: [{ title: "Case study", linkTo: "/August", newTab: false }],
};

export const peerTreeProjectInfo = {
  title: "Peer-Tree",
  image: "PeerTreeCover_uip4qv",
  tags: peerTreeTags,
  linkTo: "/PeerTree",
  description:
    "Peer-Tree revolutionizes learning by connecting users to diverse communities, curated collections, and expert insights. Through collaboration and partnerships, it creates an engaging space for knowledge sharing and growth.",
  links: [
    { title: "Case study", linkTo: "/PeerTree", newTab: false },
    { title: "Website", linkTo: "https://www.peer-tree.com/", newTab: true },
  ],
};

export const augustImages = [
  { image: "augustView1_dfgurk", paddingBottom: 10 },
  { image: "augustView2_c80fqm", paddingBottom: 60 },
  { image: "augustView3_ecb6nn", paddingBottom: 60 },
  { image: "augustView4_ndwbnx", paddingBottom: 200 },
  { image: "augustView5_lijz3q", paddingBottom: 200 },
  { image: "augustView6_jeei0y", paddingBottom: 200 },
  { image: "augustView7_qho9b5", paddingBottom: 200 },
  { image: "augustView8_svikgz", paddingBottom: 200 },
  { image: "augustView9_fz7pkb", paddingBottom: 150 },
  { image: "augustView10_szwtuw", paddingBottom: 200 },
  { image: "augustView11_qrx7cb", paddingBottom: 200 },
  { image: "augustView12_n0dtkj", paddingBottom: 200 },
  { image: "augustView13_vtfgko", paddingBottom: 200 },
  { image: "augustView14_d4udej", paddingBottom: 200 },
  { image: "augustView15_nvfdki", paddingBottom: 200 },
  { image: "augustView16_fofwgu", paddingBottom: 100 },
  { image: "augustView17_pk2fii", paddingBottom: 0 },
];

export const peerTreeImages = [
  { image: "pt1_sg61eo", paddingBottom: 160 },
  { image: "pt2_nzvnlb", paddingBottom: 160 },
  { image: "pt3_c3pewa", paddingBottom: 160 },
  { image: "pt4_kspuun", paddingBottom: 160 },
  { image: "pt5_hcdk1t", paddingBottom: 160 },
  { image: "pt6_gbwdit", paddingBottom: 160 },
  { image: "pt7_kch3fi", paddingBottom: 160 },
  { image: "pt8_os6ebr", paddingBottom: 160 },
  { image: "pt9_vzkpnp", paddingBottom: 160 },
  { image: "pt10_rpvfxo", paddingBottom: 160 },
  { image: "pt11_e4yxde", paddingBottom: 160 },
  { image: "pt12_qxa0eb", paddingBottom: 160 },
  { image: "pt13_pirqzu", paddingBottom: 160 },
];
